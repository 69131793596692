import request from "@/utils/system/request";

export default {
  findList(page: number,size:number) {
    return request({
      url: `admin/blogLinks/FindList/${size}/${page}`,
      method: "get"
    })
  },
  deleteLink(Id: number) {
    return request({
      url: `admin/blogLinks/delete/${Id}`,
      method: "delete"
    })
  }
}
