import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import service from "@/api/admin/link";
import tools from "@/utils/tools";
export default defineComponent({
  setup() {
    const state = reactive({
      table: {
        page: 1,
        size: 10,
        total: 0,
        data: [],
        loading: false,
        categoryItem: [],
        tagItem: []
      }
    });
    const methods = {
      findList() {
        state.table.loading = true;
        service.findList(state.table.page, state.table.size).then(res => {
          console.log(res);
          state.table.data = res.data.data;
          state.table.page = res.data.page;
          state.table.size = res.data.size;
          state.table.total = res.data.total;
        }).finally(() => {
          state.table.loading = false;
        });
      },

      handlePageChange() {
        methods.findList();
      },

      deleteLink(Id) {
        service.deleteLink(Id).then(res => {
          tools.message("删除成功!", "success");
          methods.findList();
        });
      }

    };
    onMounted(() => {
      methods.findList();
    });
    return { ...toRefs(state),
      ...methods
    };
  }

});